.wp-block-post-terms {
  display: grid;

  &.taxonomy-location a {
    --background-color: var(--color-gold-transparent-80);
    --text-color: #fff;

    @apply px-xs py-1.5;
    border-radius: 8px 0;
  }

  &.taxonomy-location:not([class$='-font-size']) a {
    @apply text-sm;
  }

  &.taxonomy-location:not(.has-background) a {
    background-color: var(--background-color);
  }

  &.taxonomy-location:not(.has-text-color) a {
    color: var(--text-color);
  }

  &.taxonomy-location.has-location-term-calgary:not(.has-background) a {
    --background-color: var(--color-maroon-transparent-80);
  }

  &.taxonomy-location.has-location-term-vancouver:not(.has-text-color) a {
    --background-color: var(--color-navy-transparent-80);
  }
}
